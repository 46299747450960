import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Simulacre d'Entrevista 2024 (1 hora) [Custom]</Title>
            </div>
        </ProductTemplate>
    );
};
export default Product;
